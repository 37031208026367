<template>

  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-form-equivalent-unit"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>

    <b-row class="justify-content-center">
    
      <form class="full" id="formSubproject" ref="formSubproject">

        <b-alert
          show
          variant="danger"
          v-bind:key="error"
          v-for="error in errors"
          >{{ error }}</b-alert
        >

        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Unidad de Medida"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(unidadMedidaSelected)
            }"
          >
            <a-multiselect
              name="unidad_medida"
              track-by="id"
              label="label"
              v-model="unidadMedidaSelected"
              :multiple="false"
              :hide-selected="true"
              placeholder="Seleccione"
              :options="unidadMedidaOptions"
              :allow-empty="false"
              @select="onSelect($event, 'unidad_medida')"
            />
            <input
              type="hidden"
              name="unidad_medida_hidden"
              v-model="unidad_medida_hidden"
            />
          </div>

        </trazo-form-label-slot>

        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Unidad Equivalente"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(unidadEquivalenteSelected)
            }"
          >
            <a-multiselect
              name="unidad_equivalente"
              track-by="id"
              label="label"
              v-model="unidadEquivalenteSelected"
              :multiple="false"
              :hide-selected="true"
              placeholder="Seleccione"
              :options="unidadEquivalenteOptions"
              :allow-empty="false"
              @select="onSelect($event, 'unidad_equivalente')"
            />
            <input
              type="hidden"
              name="unidad_equivalente_hidden"
              v-model="unidad_equivalente_hidden"
            />
          </div>

        </trazo-form-label-slot>

        <trazo-form-input
          id="input-factor"
          v-model="factor"
          name="factor"
          label="Factor de Conversión"
          labelPosition="top"
          type="text"
        />

        <trazo-form-input
          id="input-sumando"
          v-model="sumando"
          name="sumando"
          label="Sumando (Adición) de Conversión"
          labelPosition="top"
          type="text"
        />

        <b-form-checkbox v-model="is_active" value="1" unchecked-value="0">
          Está activo
        </b-form-checkbox>

      </form>

    </b-row>

  </trazo-off-canvas>

</template>

<script>

import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import "vue-multiselect/dist/vue-multiselect.min.css";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";

function initialState() {
  return {
    textModal: "NUEVA UNIDAD EQUIVALENTE",
    id: "",
    unidadMedidaSelected: null,
    unidad_medida_hidden: "",
    unidadEquivalenteSelected: null,
    unidad_equivalente_hidden: "",
    factor: "",
    sumando: "",
    is_active: 1,

    isTouchedForm: false,
    edit: false,
    errors: [],
    sending: false
  };
}

export default Vue.component("modal-form-equivalent-unit", {

  data() {
    return initialState();
  },

  props: ["objForm", "unidadMedidaOptions", "unidadEquivalenteOptions" , "isOpen", "selectorToggle"],

  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    },
    unidadMedidaOptions(val){
      console.log("unidadMedidaOptions=>",val);
    },
    unidadEquivalenteOptions(val){
      console.log("unidadEquivalenteOptions=>",val);
    }
  },
  methods: {
    closeModal() {
      this.resetForm();
      this.clearForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    isInvalidSelectNull(selectedItems) {
      return this.isTouchedForm && selectedItems === null;
    },
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        this.$data[`${itemName}_hidden`] = value.id;
      }
    },
    clearForm() {
      this.unidadMedidaSelected = null;
      this.unidad_medida_hidden = "";
      this.unidadEquivalenteSelected = null;
      this.unidad_equivalente_hidden = "";
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.id ? ApiService.patch : ApiService.post;
        this.errors = [];
        this.sending = true;
        saveFunction(`api/measure-equivalent-unit/${this.id ? this.id + "/" : ""}`, {
          measure_unit: this.unidad_medida_hidden,
          equivalent_unit: this.unidad_equivalente_hidden,
          factor: Number(this.factor) ? Number(this.factor) : null,
          sum: Number(this.sumando) ? Number(this.sumando) : null,
          is_active: this.is_active==1 ? true:false
        })
          .then(({ data }) => {
            this.$emit("toast", {
              message: `Se ha ${
                this.edit ? "editado" : "creado"
              } el item correctamente`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.$emit(this.edit ? "updated" : "created", data);
            this.sending = false;
            this.closeModal();
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach((object) => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
      this.fv.on("core.form.invalid", () => {
        this.isTouchedForm = true;
      });
    },
    setData() {
      if (!this.custom_lodash.isEmpty(this.objForm)) {

        console.log("dat=>",this.objForm);

        this.id = this.objForm.id;
        this.unidadMedidaSelected = this.unidadMedidaOptions.filter((el) => el.id === this.objForm.measure_unit)[0];
        this.unidad_medida_hidden = this.objForm.measure_unit;
        this.unidadEquivalenteSelected = this.unidadEquivalenteOptions.filter((el) => el.id === this.objForm.equivalent_unit)[0];
        this.unidad_equivalente_hidden = this.objForm.equivalent_unit;
        this.factor = this.objForm.factor ? this.objForm.factor.toString():'';
        this.sumando = this.objForm.sum ? this.objForm.sum.toString():'';
        this.is_active = this.objForm.is_active ? 1:0;
        this.textModal = "EDITAR UNIDAD EQUIVALENTE";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          unidad_medida_hidden: {
            validators: {
              notEmpty: {
                message: "La unidad de medida es obligatorio"
              }
            }
          },
          unidad_equivalente_hidden: {
            validators: {
              notEmpty: {
                message: "La unidad equivalente es obligatorio"
              }
            }
          },
          /* factor: {
            validators: {
              notEmpty: {
                message: "El Factor es obligatorio"
              }
            }
          },
          sumando: {
            validators: {
              notEmpty: {
                message: "El sumando es obligatorio"
              }
            }
          }, */
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();
  },
  components: {
    TrazoFormInput,
    TrazoOffCanvas,
    TrazoFormLabelSlot
  }
});
</script>
